import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ProductService } from '../../../../services/product.service';
import { AuthService } from '../../../../services/auth.service';
import { NgxSpinnerService } from "ngx-spinner"; 
import { faTh, faList } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.sass']
})
export class ProductCategoryComponent implements OnInit {
  public params: any = {};
  category:any;
  search:any;
  faTh = faTh;
  faList = faList;
  items:any;
  group:any;
  id:any;
  list = true;
  grid = false;
  variant = true;
  showBtn=-1;
  hiddenvariant = false;
  public skeletonArray = Array(6);
  defaultimg = "../assets/img/default-img.png"
  shopimagesmedium = "https://msgcms.msgnl.com/msgcms_web/RED/ShopImagesMedium/"
  constructor(private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService, public authService: AuthService, public router: Router, private productService:ProductService) { }

  ngOnInit(): void {
    this.category = this.activatedRoute.snapshot.params.category;
    this.params.item = this.category
    this.search = this.activatedRoute.snapshot.params.search;
    this.params.item = this.search
    if(this.category){
      this.loadProducts(this.category);
    }else{
      this.loadProductssearch(this.search);
    }
  }

  public onError(): void {
    this.defaultimg = "../assets/img/default-img.png";
}

ShowVariant(id) {
  let code = id
  this.variant = false;
  this.showBtn=id;
  this.hiddenvariant = true;
}
HideVariant(id) {
  console.log(id)
  this.variant = true;
  this.hiddenvariant = false;
}

ChangeGrid() {
  this.grid = true;
  this.list = false;
}
ChangeList() {
    this.grid = false;
    this.list = true;
}

async Go(item:any) {
  const url = this.router.url.split('?')[0];
  console.log(url)
  this.router.navigate(['/product/' + item]);
  
}



  async loadProducts(item:any) {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    // let code = item.substr(6, 7)
    // let key = item.substr(13, 6)
    // this.blartkey = item.substr(19,5);
    // this.pbmblartkey = item.substr(13, 6)
    // console.log('pbm artkey', item.substr(19, 5))
    // console.log(code)
    // console.log(key)
    this.spinner.show();  
    this.productService.getProductsByCategory(item)
      .subscribe(res => {
        // console.log('products', res);
        if(res){
        this.items = res['stArticles']['arrArticleInList'];
        this.group = res['stGroupInfo'];
        this.spinner.hide();
        }
        // this.filters = res['stPropFilter']['arrProp'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });

  }

  async loadProductssearch(item:any) {
    this.spinner.show(); 
    this.productService.getProductsByCategorySearch(item)
      .subscribe(res => {
        // console.log('products', res);
        this.spinner.hide();
        this.items = res['stArticles']['arrArticleInList'];
        this.group = res['stGroupInfo'];
        // this.filters = res['stPropFilter']['arrProp'];
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });

  }

}
