import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../../../services/cart.service';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from "ngx-spinner"; 


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public cartItems : Observable<CartItem[]> = of([]);
  public shoppingCartItems  : CartItem[] = [];
  status:any;
  orderline: any = '';
  orderprice:any;
  number:any;
  maxamount: number = 999999;
  changeamount: any;
  constructor(private cartService: CartService,private spinner: NgxSpinnerService, private authService: AuthService) { }

  ngOnInit() {
    if(this.authService.localCartStorageItem()){
      if(!this.authService.checkOrderNumber())
      if(window.localStorage.getItem('OrderNumber') > "0"){
        // console.log('fired cart')
        this.getOrderlines();
      }
      // this.loadMinusCartItems()
      }else{
      // if(window.localStorage.getItem('PBMOrderNumber') > "0"){
      //   console.log('fired cart')
      //   this.getPBMOrderlines();
      // }
      }
  }

  async getOrderlines() {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    this.spinner.show();  
    if(window.localStorage.getItem('OrderNumber') > "0"){
      this.cartService.getOrderLine()
      .subscribe(data => {
        console.log('status', data);
        if(data.status === 204 || data.status === 401){
          // let data1 = data.data
          // let parsed = JSON.parse(data.data);
        this.orderline = '';
        this.status = data.status
        this.orderprice = 0;
        }else{
          let data1 = data.data
          this.orderline = data['stOrderLines'].arrOrderLine;
          this.status = data.status
          this.orderprice = data['stOrderLines'];
          this.spinner.hide();
        }
        if(data.status === 204){
          // this.presentAlertOrder();
          // this.navigateTo('/1/category')
        }

        // if(data.status === 401){
        //   this.navigateTo('/login')
        //   this.presentAlert();
        // }

        // if(data.status === 404){
        //   this.navigateTo('/1/category')
        //   this.presentAlertOrder();
        // }
        // this.status = data.status
        // this.orderprice = parsed.stOrderLines;
        // console.log('orderline', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();

      }, err => {
        console.log(err);
        console.log(err.status);
        if(this.authService.checkGuestLogin){
          if(err.status === 401){
            // this.presentAlert();
            // this.navigateTo('/1/home')
          }
  
          if(err.status === 404){
            // this.presentAlertOrder();
            // this.navigateTo('/1/home')
          }
          // if(err.status === 204){
          //   this.presentAlertOrder();
          //   this.navigateTo('/1/category')
          // }
          this.orderline = '';
          this.status = 401
          this.orderprice = 0;
        }else{
          if(err.status === 401){
            // this.presentAlert();
            // this.navigateTo('/login')
          }
  
          if(err.status === 404){
            // this.presentAlertOrder();
            // this.navigateTo('/1/category')
          }
          // if(err.status === 204){
          //   this.presentAlertOrder();
          //   this.navigateTo('/1/category')
          // }
          this.orderline = '';
          this.status = 401
          this.orderprice = 0;
        }
        // loading.dismiss();

      });
  }
}

removeItem(itm, index) {
  // this.getOrderlines();
  // console.log('lineID', index)
  let cartProductRest =       {
    "sArticleCode":itm.sArticleCode,
    "sBlArtKey":"",
    "nAmount": 0, 
  };

  // console.log(cartProductRest)

  // if(this.orderline[index].iLineID){
    // this.cartService.deleteOrderLineNative(cartProductRest, index);
      this.cartService.deleteOrderLine(cartProductRest, index).subscribe(res => {
        // console.log(res)
      });
      setTimeout(() => {
        this.getOrderlines();
      }, 2000);
      // this.cartService.removeLocalCartProduct(itm);
      // this.presentLoading();
      // console.log(this.cartService.postOrderLineNative(cartProductRest))
      // console.log('remove native basket')
  // }
  // Recalling
  // this.getOrderlines();
}

losesFocus(event: any = {}, item:any) {
  // console.log(event)
  if(item.nAmount === NaN){
    // console.log('is nan')
    item.nAmount = 1
    item.amount = 1 * (item.stPrice.curNetPriceInc);
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 1000);
  }
  if(event.type === "ionChange" ){
    if(event.detail.value === ""){
      // console.log('is nan input')
      item.nAmount= 1
      item.amount = 1 * (item.stPrice.curNetPriceInc);
      this.changeamount = item.nAmount;
      setTimeout(() => {
        this.ChangeCart(item, item.iLineID, this.changeamount)
        // this.getOrderlines();
      }, 1000);
    }else{
      item.nAmount= parseInt(event.detail.value)
      this.changeamount = item.nAmount;
      setTimeout(() => {
        this.ChangeCart(item, item.iLineID, this.changeamount)
        // this.getOrderlines();
      }, 1000);
    }
  }else if(event.type === "click"){
    // const oke = item.nQuantity
    // console.log('grealet', oke)
    // const y = this.focusquantity
    // const yeah = oke + item.nQuantityStep;
    // console.log(yeah)
    // item.nQuantity = yeah
    item.nAmount= isNaN(item.nAmount) ? 0 : item.nAmount;
    item.nAmount =  item.nAmount + item.nAmountStep;
    var newvalue = Math.floor(item.nAmount/item.nAmountStep) * item.nAmountStep;
    if(newvalue < item.nAmountMin) { newvalue = item.nAmountMin;}
    if(this.maxamount>0) { if(newvalue > this.maxamount) { newvalue = this.maxamount; } }
    item.nAmount = newvalue
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 1000);
  }else{
    item.nAmount = item.nAmountStep
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 1000);
  }
}

losesMinFocus(event: any = {}, item:any) {
  console.log(event)
  if(item.nAmount === NaN){
    // console.log('is nan')
    item.nAmount= 1
    item.amount = 1 * (item.stPrice.curNetPriceInc);
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 1000);
  }
  if(event.type === "ionChange"){
    if(event.detail.value === ""){
      // console.log('is nan input')
      item.nAmount = 1
      item.amount = 1 * (item.stPrice.curNetPriceInc);
      this.changeamount = item.nAmount;
      setTimeout(() => {
        this.ChangeCart(item, item.iLineID, this.changeamount)
        // this.getOrderlines();
      }, 1000);
    }else{
      item.nAmount = parseInt(event.detail.value)
      this.changeamount = item.nAmount;
      setTimeout(() => {
        this.ChangeCart(item, item.iLineID, this.changeamount)
        // this.getOrderlines();
      }, 1000);
    }
  }else if(event.type === "click"){
    item.nAmount= isNaN(item.nAmount) ? 0 : item.nAmount;
    item.nAmount = item.nAmount - item.nAmountStep;
    var newvalue = Math.floor(item.nAmount/item.nAmountStep) * item.nAmountStep;
    if(newvalue < item.nAmountMin) { newvalue = item.nAmountMin;}
    if(this.maxamount>0) { if(newvalue > this.maxamount) { newvalue = this.maxamount; } }
    item.nAmount = newvalue
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 2000);
  }else{
    item.nAmount = item.nAmountStep
    this.changeamount = item.nAmount;
    setTimeout(() => {
      this.ChangeCart(item, item.iLineID, this.changeamount)
      // this.getOrderlines();
    }, 2000);
  }
}

ChangeCart(itm, index, quantity){
  // console.log(index)
  // console.log(index)
  let cartProductRest =       {
    "sArticleCode":itm.sArticleCode,
    "sBlArtKey":"",
    "nAmount": quantity, 
  };
  this.cartService.deleteOrderLine(cartProductRest, index).subscribe(res => {
    // console.log(res)
  });
  setTimeout(() => {
    this.getOrderlines();
  }, 1000);

}




  //   // Remove cart items
  //   public removeItem(item: CartItem) {
  //     this.cartService.removeFromCart(item);
  //   }


  //  // Increase Product Quantity
  //  public increment(product: any, quantity: number = 1) {
  //   this.cartService.updateCartQuantity(product,quantity);
  // }

  // // Decrease Product Quantity
  // public decrement(product: any, quantity: number = -1) {
  //   this.cartService.updateCartQuantity(product,quantity);
  // }
  //  // Get Total
  //  public getTotal(): Observable<number> {
  //   return this.cartService.getTotalAmount();
  // }
  

}
