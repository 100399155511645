import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from './services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';


declare var navbar: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Redson';
  form: FormGroup;
  email: any;
  password: any;
  user:any;
  fullname;
  checked: any;
  searchTerm: string = '';
  constructor(private router: Router, private spinner: NgxSpinnerService, public authService: AuthService,private formBuilder: FormBuilder) {}


  ngOnInit() {
    /** spinner starts on init */
    this.form = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.minLength(3),
        Validators.required
      ])),
    });
    window.localStorage.setItem('check', 'false');
    this.checked = window.localStorage.getItem('check');
    // console.log('App version MSG', this.appV.getVersionNumber());
    // this.appversion = this.appV.getVersionNumber(); 
    this.email = window.localStorage.getItem('email');
    this.password = window.localStorage.getItem('password');
    // this.form.get('email').setValue(this.email, {
    //   onlyself: true
    // })
    // this.form.get('password').setValue(this.password, {
    //   onlyself: true
    // })
    console.log(this.form.value.email)

    window.scroll(0,0);
    this.fullname = window.localStorage.getItem('FullName');
    this.navbar();
    this.authService.SwitchloginNative();
  }

  onSearch(event: any = {}) {
    const query = event.target.value;
    const url = this.router.url.split('?')[0];
    this.router.navigate(['webshop/search/' + query]);
  }

  navbar(){
    new navbar()
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  login(value) {
    let me=this;
    let loginParams = {
      "sUser":this.form.value.email,
      "sPass":this.form.value.password,
      // "sUser":"12345PALS",
      // "sPass":"pals",
      // "sUser":"12345BOER",
      // "sPass":"f1BawK37B2",
      // "sUser":"12345MLAKKE",
      // "sPass":"Ivana003",
      "iUsId": 0, 
      "iPBMMode" : 0
    }
    this.spinner.show(); 
    window.localStorage.setItem('username', this.form.value.email);
    window.localStorage.setItem('password2', this.form.value.password);
    console.log(this.email);
    console.log(loginParams);
    this.authService.login(loginParams).then(res => {
      console.log(res)
      if(res){
        this.spinner.hide();
      setTimeout(() => {
      if(this.authService.CheckLoginUser()){
      if (this.authService.bAllowShop === false) {
        // this.router.navigate(['/1/pbm-list']);
      } else {
        console.log(res);
        // if(this.authService.iUserType === 5){
        //   this.authService.storeSalesUserCredentials(res);
        // }
      }
    } else {
      // this.router.navigate(['/']);
      // console.log(res);
      // console.log('Ordernumber check', this.authService.OrderNumber)
      // console.log('Continue with the old order')
      // this.navigateTo('/1//home');
    }
  }, 2000);
}
    });
    console.log(this.authService.iPbmMode);

 
  }

  onLogout() {
    this.authService.loginOff().subscribe(res => {
      console.log('logout', res)
      if (this.authService.iPbmMode === 0) {
        // this.navigateTo('/1/home');
        this.authService.destroyUserCredentials();
        this.Guestlogin();
      } else {
        console.log(res);
        this.authService.destroyUserCredentials();
        this.Guestlogin();
        // this.navigateTo('/1/home');
      }
    });
    console.log(this.authService.iPbmMode);

  }

  Guestlogin() {

    // let loader = this.loadingCtrl.create({
    //   content: 'Authenticating..'
    // });
    // loader.present();
    let me=this;
    let loginParams = null
    // console.log(this.email);
    console.log('empty2', loginParams);
    this.authService.Guestlogin().then(res => {
      console.log(res)
        // this.navigateTo('/1//home');
    });

    console.log(this.authService.iPbmMode);
  
  //  }
  
  }

}
