import { Component, OnInit } from '@angular/core';
// import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CartService } from '../../../services/cart.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  public cartItems: Observable<CartItem[]> = of([]);
  public buyProducts: CartItem[] = [];

  amount: number;
  payments: string[] = ['Create an Account?', 'Flat Rate'];
  paymantWay: string[] = ['Direct Bank Transfer', 'PayPal'];
  form: FormGroup;
  public formfact: FormGroup;
  public formdeliver: FormGroup;
  order: any;
  fact:any;
  number:any;
  email:any;
  paymentmethod:any;
  deliverydate: any = "0000-00-00";
  deliverytype: any = 1;
  orderlines:any;
  factaddress:any;
  user:any = [];
  public address: any;
  addressinvoice:any;
  status:any;
  orderline: any = '';
  orderprice:any;
  constructor(private router: Router, private spinner: NgxSpinnerService, private cartService: CartService,private route: ActivatedRoute, public productService: ProductService,public formBuilder: FormBuilder,private authService: AuthService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      notice: [''],
      street: [''],
      housenumber: [''],
      zipcode: [''],
      city: [''],
      company: [''],
      kvk: [''],
      btw: [''],
      streetinvoice: [''],
      housenumberinvoice: [''],
      zipcodeinvoice: [''],
      cityinvoice: ['']
    })
    // this.setupFormfact();
    this.getOrder();
    this.getOrderlines();
    // this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => this.buyProducts = products);
    // this.getTotal().subscribe(amount => this.amount = amount);
  }

  setupFormfact() {
    this.formfact = new FormGroup({
      street: new FormControl(null, Validators.required),
      zipcode: new FormControl(null, Validators.required),
      housenumber: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required)
    });
    // console.log(this.formfact)
  }

  thanks(){
    this.router.navigate(['/thanks']);

  }

  async getOrder() {
      this.cartService.getOrder()
      .subscribe(data => {
        this.spinner.hide();
        this.order = data['stOrderPayment'];
        this.user = data['stOrder'];
        this.address = data['stOrder']['stAddrDelivery'];
        this.addressinvoice = data['stOrder']['stAddrInvoice'];
        console.log('invoice', this.addressinvoice)
        const sOrderCorr = this.user.sOrderCorr;
        const sContactTel = this.user.sContactTel;
        const sContactMail = this.user.sContactMail;
        const sStreet = this.user.stAddrDelivery.sStreet1;
        const sHousenumber = this.user.stAddrDelivery.sHouseNumber;
        const sZipcode = this.user.stAddrDelivery.sZipCode;
        const sCity = this.user.stAddrDelivery.sCity;
        const sInvoiceStreet = this.user.stAddrInvoice.sStreet1;
        const sInvoiceHousenumber = this.user.stAddrInvoice.sHouseNumber;
        const sInvoiceZipcode = this.user.stAddrInvoice.sZipCode;
        const sInvoiceCity = this.user.stAddrInvoice.sCity;
        const sCompany = this.user.sOrderName;
        const sBTW = this.user.sOrderVatNr;
        const sKVK = this.user.sOrderKvkNr;
        this.form.get('name').setValue(sOrderCorr, {
          onlyself: true
        })
        this.form.get('email').setValue(sContactMail, {
          onlyself: true
        })
        this.form.get('telephone').setValue(sContactTel, {
          onlyself: true
        })
        this.form.get('streetinvoice').setValue(sInvoiceStreet, {
          onlyself: true
        })
        this.form.get('housenumberinvoice').setValue(sInvoiceHousenumber, {
          onlyself: true
        })
        this.form.get('zipcodeinvoice').setValue(sInvoiceZipcode, {
          onlyself: true
        })
        this.form.get('cityinvoice').setValue(sInvoiceCity, {
          onlyself: true
        })
        this.form.get('street').setValue(sStreet, {
          onlyself: true
        })
        this.form.get('housenumber').setValue(sHousenumber, {
          onlyself: true
        })
        this.form.get('zipcode').setValue(sZipcode, {
          onlyself: true
        })
        this.form.get('city').setValue(sCity, {
          onlyself: true
        })
        this.form.get('company').setValue(sCompany, {
          onlyself: true
        })
        this.form.get('btw').setValue(sBTW, {
          onlyself: true
        })
        this.form.get('kvk').setValue(sKVK, {
          onlyself: true
        })
        // console.log(this.user)
        if(!this.authService.checkGuestLogin()){
          this.address = data['stOrder'].stAddrDelivery;
          this.factaddress = data['stOrder'].stAddrInvoice;
        }
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();
      }, err => {
        // console.log(err);
        // console.log('order error Forbidden', err.status)
        if(this.authService.checkGuestLogin()){
          if(err.status === 401 || err.status === 403 || err.status === 400){
            // this.presentAlertGuest();
            // this.navigateTo('/1/home')
          }
        }else{
          if(err.status === 401 || err.status === 403 || err.status === 400){
            // this.presentAlert();
            // this.navigateTo('/login')
          }
        }
        // loading.dismiss();
      });
  }

  async getOrderlines() {
    // const loading = await this.loadingController.create({
    //   message: 'Please wait...',
    // });
    // await loading.present();
    if(window.localStorage.getItem('OrderNumber') > "0"){
      this.cartService.getOrderLine()
      .subscribe(data => {
        // console.log('status', data);
        if(data.status === 204 || data.status === 401){
          // let data1 = data.data
          // let parsed = JSON.parse(data.data);
        this.orderline = '';
        this.status = data.status
        this.orderprice = 0;
        }else{
          let data1 = data.data
          this.orderline = data['stOrderLines'].arrOrderLine;
          this.status = data.status
          this.orderprice = data['stOrderLines'];
        }
        if(data.status === 204){
          // this.presentAlertOrder();
          // this.navigateTo('/1/category')
        }

        // if(data.status === 401){
        //   this.navigateTo('/login')
        //   this.presentAlert();
        // }

        // if(data.status === 404){
        //   this.navigateTo('/1/category')
        //   this.presentAlertOrder();
        // }
        // this.status = data.status
        // this.orderprice = parsed.stOrderLines;
        // console.log('orderline', data);
        // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
        // loading.dismiss();

      }, err => {
        // console.log(err);
        // console.log(err.status);
        if(this.authService.checkGuestLogin){
          if(err.status === 401){
            // this.presentAlert();
            // this.navigateTo('/1/home')
          }
  
          if(err.status === 404){
            // this.presentAlertOrder();
            // this.navigateTo('/1/home')
          }
          // if(err.status === 204){
          //   this.presentAlertOrder();
          //   this.navigateTo('/1/category')
          // }
          this.orderline = '';
          this.status = 401
          this.orderprice = 0;
        }else{
          if(err.status === 401){
            // this.presentAlert();
            // this.navigateTo('/login')
          }
  
          if(err.status === 404){
            // this.presentAlertOrder();
            // this.navigateTo('/1/category')
          }
          // if(err.status === 204){
          //   this.presentAlertOrder();
          //   this.navigateTo('/1/category')
          // }
          this.orderline = '';
          this.status = 401
          this.orderprice = 0;
        }
        // loading.dismiss();

      });
  }
}

async orderfactchange(email) {
  // if(this.paymentmethod === true){
    // console.log('this is fact pay')
      if(this.fact){
      this.cartService.putOrderFactNewFact(this.orderlines, this.factaddress, this.form, this.formfact, email, this.deliverydate, this.deliverytype).subscribe(res => {
        // console.log(res)
      });
      }else{
        // console.log('delivery 1 or 2', this.deliverytype)
      this.cartService.putOrderFact(this.orderlines, this.addressinvoice, this.form, email, this.deliverydate, this.deliverytype, this.address).subscribe(res => {
        // console.log(res)
      });
      }
  // }
  // else{
  //   console.log('this is mollie pay')
  //     if(this.fact){
  //     this.cartService.putOrderMollieNewFactNative(this.orderlines, this.adressjson, this.factaddress, this.form, this.formfact, email, this.deliverydate, this.deliverytype);
  //     }else{
  //     this.cartService.putOrderMollieNative(this.orderlines, this.adressjson, this.factaddress, this.form, email, this.deliverydate, this.deliverytype);
  //     }
  // }
// const loading = await this.loadingController.create({
//   message: 'Laden..',
//   duration: 6000
// });
// await loading.present();
this.spinner.show();
setTimeout(() => {
  if (!this.cartService.ideal) {

  // this.router.navigateByUrl('/thanks/checkout/' + window.localStorage.getItem('OrderNumber'));
  this.router.navigate(['/thanks']);
  this.spinner.hide();
  }
  else{
    // this.openbrowser();
    }
  }, 6000);
this.cartService.removeorderlocal()  
this.cartService.calculateLocalCartProdCounts();

}

onPlaceOrder(){

}

}
