import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';


@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrls: ['./product-variant.component.sass']
})
export class ProductVariantComponent implements OnInit {
  public params: any = {};
  item:any
  items:any
  properties:any;
  variants:any;
  productid:any;
  constructor(private route: ActivatedRoute, public authService: AuthService, public productService: ProductService, private router: Router) { }

  ngOnInit(): void {
    this.productid = this.route.snapshot.params.id;
    this.params.id = this.productid
    console.log(this.productid)
    this.loadProducts(this.productid)
  }

  async loadProducts(item:any) {
    this.productService.getProductsByCategory(item)
      .subscribe(res => {
        console.log('products', res);
        if(res){
        this.items = res['stArticles']['arrArticleInList'];
        }
      }, err => {
        console.log(err);
      });
  }

  async Go(item:any) {
    const url = this.router.url.split('?')[0];
    console.log(url)
    this.router.navigate(['/product/' + item]);
    
  }
  

}
