import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Product, ColorFilter } from 'src/app/modals/product.model';
import { Router } from '@angular/router';
import { CategoryService } from '../../../../services/category.service';
import { AuthService } from '../../../../services/auth.service';
import { NgxSpinnerService } from "ngx-spinner"; 
import { faTh, faList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.scss']
})
export class ProductLeftSidebarComponent implements OnInit {
  faTh = faTh;
  faList = faList;
  list = false;
  grid = true;
  public sidenavOpen:boolean = true;
  public categories: any;
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public tagsFilters  :   any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public colorFilters :   ColorFilter[] = [];
  shopimagesmedium = "https://msgcms.msgnl.com/msgcms_web/RED/ShopImagesMedium/";

  public items        :   Product[] = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];

  constructor(private spinner: NgxSpinnerService, private router: Router,private api2: CategoryService,private authService: AuthService,private productService: ProductService, private route: ActivatedRoute) {
    this.route.params.subscribe(
      (params: Params) => {
        const category = params['category'];
        this.productService.getProductByCategory(category).subscribe(products => {
       this.allItems = products;
       this.products = products.slice(0.8);
       this.getTags(products)
       this.getColors(products)
       this.getCategories();
        })
      }
    )
  }

  ChangeGrid() {
    this.grid = true;
    this.list = false;
  }
  ChangeList() {
      this.grid = false;
      this.list = true;
  }

  async getCategories() {
      this.spinner.show(); 
      this.api2.getCategories()
      .subscribe(res => {
        // console.log('categories', res);
        if(res){
          this.categories = res['stNavMenu']['arrMenuGroup'];
          this.spinner.hide();
        }
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
  }



     // Get current product tags
     public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

  ngOnInit() {
    if(this.authService.CheckGuestLoginUser()){
      console.log('ur still right')
     }else{
      this.Guestlogin();
     }
    // this.postOrder();
    // this.getOrderlist();
    // setTimeout(() => {
      if(this.authService.checkGuestLogin()){

      }else{
        // this.getOrderlist();
        // this.getClientInfo();
      }
  }

  Guestlogin() {

    // let loader = this.loadingCtrl.create({
    //   content: 'Authenticating..'
    // });
    // loader.present();
    let me=this;
    let loginParams = null
    // console.log(this.email);
    console.log('empty2', loginParams);

    this.authService.Guestlogin().then(res => {
  
        console.log('test login page', JSON.stringify(res));
        // this.navigateTo('/1//home');
        // this.authService.guestlogin(JSON.stringify(res));
        console.log
        // this.authService.guestlogin(JSON.parse(res.data));
  
    });
  
 
    // this.authService.Guestlogin().then(res => {
    //     this.navigateTo('/1//home');
    // });

    console.log(this.authService.iPbmMode);
  
  //  }
  
  }



  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
    // Animation Effect fadeIn
    public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

    // Update tags filter
    public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

     // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });

}

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}


  // Update price filter
//   public updatePriceFilters(price: any) {
//     let items: any[] = [];
//     this.products.filter((item: Product) => {
//         if (item.price >= price[0] && item.price <= price[1] )  {
//            items.push(item); // push in array
//         }
//     });
//     this.items = items;
// }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


   this.allItems = this.products.filter((item: Product) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });
     console.log(this.products);

}

onBrendsChanged(newBrend) {
  console.log(newBrend);
  this.allItems = newBrend === 'all' ? this.products : this.products.filter(

    item => item.brand === newBrend
  )
  console.log(this.allItems);


}
}
