import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
// import { Platform } from '@ionic/angular';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { map } from 'rxjs/operators';
// import { Storage } from '@ionic/storage';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
// import * as sha512 from 'js-sha512';
import * as sha512 from 'sha512';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/toPromise';
import base64url from "base64url";
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/fromPromise';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userKey = 'my-user-group';
  user: any;
  userid: any;
  PBMuserKey = 'my-user-group';
  PBMuser: any;
  isLoggedIn = false;
  AuthToken: string;
  SalesAuthToken: string
  bAllowPbm: any;
  bAllowShop: any;
  FullName: any;
  iPriceType:any;
  PBMAuthToken: string;
  iUserId: any;
  iClient: any;
  iPbmMode: any;
  login2: any
  iUserType: any;
  iSalesUserType: any;
  bLoggedIn: any;
  fullname: string;
  item: any;
  OrderNumber: any;
  constructor(private http: HttpClient) { 
    // this.loadUserCredentials();

  }

  //   async login(loginParams){
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     let url = '/Authorisation'; 
  //     console.log(JSON.stringify(loginParams));
  //     let user = this.http.post(url, JSON.stringify(loginParams), options)
  //     .subscribe( data => {
  //         if(data.['stAuthResp']){
  //             this.storeUserCredentials(data.['stAuthResp'].sSessionID);
  //             this.storeUserID(data.['stAuthResp'].iUserId);
  //             this.storage.set('userToken', this.AuthToken);
  //             this.storePBM(data.['stAuthResp'].iPbmMode);
  //             console.log(data.['stAuthResp'].iPbmMode);
  //             console.log(data.json());
  //             this.userFullname(data.['stAuthResp'].sFullName);
  //             resolve(true);
  //         }
  //         else
  //             resolve(false);
  //     });
  //   });
  // }

  public checkOrderNumber(): boolean {
    if (window.localStorage.getItem('OrderNumber') === "0" || window.localStorage.getItem('OrderNumber') === null || window.localStorage.getItem('OrderNumber') === "undefined") {
      return true
    } else {
      return false;
    };
  }

  public checkGuestLogin(): boolean {
    if (window.localStorage.getItem('iClient') === "300000") {
      return true
    } else {
      return false;
    };
  }


  public checkPriceTypeNoPrice(): boolean {
    if (window.localStorage.getItem('PriceType') === "0") {
      return true
    } else {
      return false;
    };
  }

  public checkPriceTypeGrossPrice(): boolean {
    if (window.localStorage.getItem('PriceType') === "1") {
      return true
    } else {
      return false;
    };
  }

  public checkPriceTypeNettoPriceExcl(): boolean {
    if (window.localStorage.getItem('PriceType') === "2") {
      return true
    } else {
      return false;
    };
  }

  public checkPriceTypeNettoPriceIncl(): boolean {
    if (window.localStorage.getItem('PriceType') === "3") {
      return true
    } else {
      return false;
    };
  }

  public checkPBMOrderNumber(): boolean {
    if (window.localStorage.getItem('PBMOrderNumber') === "0" || window.localStorage.getItem('PBMOrderNumber') === null || window.localStorage.getItem('PBMOrderNumber') === "undefined") {
      return true
    } else {
      return false;
    };
  }

  public localAgent(): boolean {
    if (window.localStorage.getItem("iSalesUserType") === "5") {
      return true
    } else {
      return false;
    };
  }

  public CheckLoginUser(): boolean {
    if (window.localStorage.getItem('bLoggedIn') === "true") {
      return true
    } else {
      return false;
    };
  }

  public CheckGuestLoginUser(): boolean {
    if (window.localStorage.getItem('bLoggedIn') === "false" || window.localStorage.getItem('bLoggedIn') === "true") {
      return true
    } else {
      return false;
    };
  }


  public CheckLoginToken(): boolean {
    if (window.localStorage.getItem('checktokenloginUser') === "false" || window.localStorage.getItem('checktokenloginUser') === "" || window.localStorage.getItem('checktokenloginUser') === null) {
      return true
    } else {
      return false;
    };
  }

  public localUser(): boolean {
    if (window.localStorage.getItem("iUserType") === "0") {
      return true
    } else {
      return false;
    };
  }

  public SalesMan(): boolean {
    if (window.localStorage.getItem("iUserType") === "5") {
      return true
    } else {
      return false;
    };
  }

  public localStorageItem(): boolean {
    if (window.localStorage.getItem("iUserType") === "1" || window.localStorage.getItem("iUserType") === "5" || window.localStorage.getItem("iUserType") === "2") {
      return true
    } else {
      return false;
    };
  }

  public localAllowPbm(): boolean {
    if (window.localStorage.getItem("bAllowPbm") === "true") {
      return true
    } else {
      return false;
    };
  }

  public localCartStorageItem(): boolean {
    if (window.localStorage.getItem("iPbmMode") === "0" || window.localStorage.getItem("iPbmMode") === "-1") {
      return true
    } else {
      return false;
    };
  }

  public localPBMCartStorageItem(): boolean {
    if (window.localStorage.getItem("iPbmMode") === "1") {
      return true
    } else {
      return false;
    };
  }

  public orderlist(): boolean {
    if (window.localStorage.getItem("iPbmMode") === "-1") {
      return true
    } else {
      return false;
    };
  }

  public normalorder(): boolean {
    if (window.localStorage.getItem("iPbmMode") === "0" || window.localStorage.getItem("iPbmMode") === "-1") {
      return true
    } else {
      return false;
    };
  }

  public PBMorder(): boolean {
    if (window.localStorage.getItem("iPbmMode") === "1") {
      return true
    } else {
      return false;
    };
  }

  async login(loginParams) {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Request-Headers': '*',
        "UserToken": `${window.localStorage.getItem("Authtoken")}`
      }
      let url = 'https://red.msgrestservices.nl/Authorisation';
      console.log(JSON.stringify('test', loginParams));
      let user = this.http.post(url, loginParams, {'headers':headers})
        .subscribe(data => {
          if (data) {
            this.storeUserCredentials(data['stAuthResp'].sSessionID);
            this.storeUserID(data['stAuthResp'].iUserId);
            console.log()
            this.storeClientID(data['stAuthResp'].iClient);
            this.storeUserType(data['stAuthResp'].iUserType);
            this.storePriceType(data['stAuthResp'].iPriceType);
            console.log(this.AuthToken);
            this.storePBM(data['stAuthResp'].iPbmMode);
            this.storeallowPBM(data['stAuthResp'].bAllowPbm);
            this.storeallowSHOP(data['stAuthResp'].bAllowShop);
            this.storeOrderNumber(data['stAuthResp'].iOrderId);
            this.storelogedIn(data['stAuthResp'].bLoggedIn);
            this.storeFullName(data['stAuthResp'].sFullName);
            // this.storeallowPBM(data.['stAuthResp'].bAllowPbm);
            console.log(data['stAuthResp'].iPbmMode);
            console.log(data);
            this.userFullname(data['stAuthResp'].sFullName);
            if(this.iUserType === 5){
              this.storeSalesUserCredentials(data['stAuthResp'].sSessionID);
              this.storeSalesUserType(data['stAuthResp'].iUserType);
            }
            resolve(true);
          }
          else
            resolve(false);
        });
    });
  }

  // async loginOff() {
  //   return new Promise((resolve, reject) => {
  //     // var headers = new Headers();
  //     // // let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     // headers.append('Access-Control-Allow-Origin', '*');
  //     // headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     // headers.append('Accept', 'application/json');
  //     // headers.append('content-type', 'application/json');
  //     const headers = {
  //       'Content-Type': 'application/json',
  //       "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //     }
  //     let url = '/Authorisation';
  //     let user = this.http.delete(url, {'headers':headers})
  //       .subscribe(data => {
  //         return data

  //       });
  //   });
  // }

  loginOff(): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'UserToken': `${window.localStorage.getItem("Authtoken")}`
    }
    console.log('postorder', headers)
    return this.http.delete('https://red.msgrestservices.nl/Authorisation', {'headers':headers})
  }

  // async passwordReset(sUser, sMail) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     let url = '/User/' + sUser + '/' + sMail;
  //     console.log(JSON.stringify('test', sUser + sMail));
  //     let user = this.http.post(url, '', options)
  //       .subscribe(data => {
  //         if (data.json()) {
  //           console.log(data.json())
  //           resolve(true);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  async Guestlogin() {
    return new Promise((resolve, reject) => {
      // var headers = new Headers();
      // // let options = new RequestOptions({ headers: headers });
      // headers.append('Access-Control-Allow-Origin', '*');
      // headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      // headers.append('Accept', 'application/json');
      // headers.append('content-type', 'application/json');
      const headers = {
        'Content-Type': 'application/json',
        "UserToken": `${window.localStorage.getItem("Authtoken")}`
      }
      let url = 'https://red.msgrestservices.nl/Authorisation';
      let user = this.http.post(url, '', {'headers':headers})
        .subscribe(data => {
          console.log(data);
          if (data) {
            this.storeUserCredentials(data['stAuthResp'].sSessionID);
            this.storeUserID(data['stAuthResp'].iUserId);
            console.log()
            this.storeClientID(data['stAuthResp'].iClient);
            this.storePriceType(data['stAuthResp'].iPriceType);
            this.storeUserType(data['stAuthResp'].iUserType);
            console.log(this.AuthToken);
            this.storePBM(data['stAuthResp'].iPbmMode);
            // this.storeallowPBM(data.['stAuthResp'].bAllowPbm);
            console.log(data['stAuthResp'].iPbmMode);
            console.log(data);
            this.userFullname(data['stAuthResp'].sFullName);
            if(this.iUserType === 5){
              this.storeSalesUserCredentials(data['stAuthResp'].sSessionID);
              this.storeSalesUserType(data['stAuthResp'].iUserType);
            }
          }
          else
            resolve(false);
        });
      console.log(user);
    });
  }

  // async logoff() {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', this.AuthToken)
  //     let url = '/Authorisation';
  //     // console.log(JSON.stringify(loginParams));
  //     let user = this.http.delete(url, options)
  //       .subscribe(data => {
  //         console.log(data);
  //         if (data.['stAuthResp']) {
  //           this.storeUserCredentials(data.['stAuthResp'].sSessionID);
  //           this.storeUserID(data.['stAuthResp'].iUserId);
  //           this.storeClientID(data.['stAuthResp'].iClient);
  //           this.storeUserType(data.['stAuthResp'].iUserType);
  //           this.storage.set('userToken', this.AuthToken);
  //           console.log(this.AuthToken);
  //           this.storePBM(data.['stAuthResp'].iPbmMode);
  //           console.log(data.['stAuthResp'].iPbmMode);
  //           console.log(data.json());
  //           this.userFullname(data.['stAuthResp'].sFullName);
  //           resolve(true);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async loginoffNative() {
  //   // let loading =  this.loadingCtrl.create();

  //   let headers = {
  //     "Content-Type": "application/json"
  //   };
  //   this.nativeHttp.setDataSerializer("json");
  //   // console.log('native', loginParams)
  //   // let params = loginParams;

  //   this.nativeHttp.delete('https://red.msgrestservices.nl/Authorisation', {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // async PBMlogin() {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', this.AuthToken)
  //     console.log('pbmlogin', this.AuthToken)
  //     // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
  //     let sha = CryptoJS.SHA512(this.AuthToken).toString(CryptoJS.enc.Base64);
  //     // let encode = CryptoJS.enc.Base64.stringify(sha)
  //     let encodedSource1 = sha.replace(/=+$/, '');
  //     let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //     let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //     let part = encodedSource3.slice(4, 25);
  //     console.log('hash', sha);
  //     console.log('hash', encodedSource3);
  //     // console.log('hash', encode);
  //     let url = '/Authorisation/' + part + '?SetPBM=On';
  //     console.log(url)

  //     let user = this.http.get(url, options)
  //       .subscribe(data => {
  //         if (data.['stAuthResp']) {
  //           this.storePBMUserCredentials(data.['stAuthResp'].sSessionID);
  //           this.storeUserID(data.['stAuthResp'].iUserId);
  //           this.storeClientID(data.['stAuthResp'].iClient);
  //           this.storeUserType(data.['stAuthResp'].iUserType);
  //           // this.storage.set('userToken', this.PBMAuthToken);
  //           // console.log(this.AuthToken);
  //           this.storePBM(data.['stAuthResp'].iPbmMode);
  //           console.log(data.['stAuthResp'].iPbmMode);
  //           console.log(data.json());
  //           this.userFullname(data.['stAuthResp'].sFullName);
  //           resolve(true);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async loginswitch() {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', this.PBMAuthToken)
  //     console.log('pbmlogin', this.PBMAuthToken)
  //     // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
  //     let sha = CryptoJS.SHA512(this.PBMAuthToken).toString(CryptoJS.enc.Base64);
  //     // let encode = CryptoJS.enc.Base64.stringify(sha)
  //     let encodedSource1 = sha.replace(/=+$/, '');
  //     let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //     let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //     let part = encodedSource3.slice(4, 25);
  //     console.log('hash', sha);
  //     console.log('hash', encodedSource3);
  //     // console.log('hash', encode);
  //     let url = '/Authorisation/' + part;
  //     console.log(url)

  //     let user = this.http.put(url, options)
  //       .subscribe(data => {
  //         if (data.['stAuthResp']) {
  //           this.storeUserCredentials(data.['stAuthResp'].sSessionID);
  //           this.storeUserID(data.['stAuthResp'].iUserId);
  //           this.storeClientID(data.['stAuthResp'].iClient);
  //           this.storeUserType(data.['stAuthResp'].iUserType);
  //           this.storage.set('userToken', this.AuthToken);
  //           console.log(this.AuthToken);
  //           this.storePBM(data.['stAuthResp'].iPbmMode);
  //           console.log(data.['stAuthResp'].iPbmMode);
  //           console.log(data.json());
  //           this.userFullname(data.['stAuthResp'].sFullName);
  //           resolve(true);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async loginNative(loginParams) {
  //   // let loading =  this.loadingCtrl.create();

  //   let headers = {
  //     "Content-Type": "application/json"
  //   };
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log('native', loginParams)
  //   let params = loginParams;

  //   this.nativeHttp.post('https://red.msgrestservices.nl/Authorisation', params, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       this.storeallowPBM(parsed.['stAuthResp'].bAllowPbm);
  //       this.storeallowSHOP(parsed.['stAuthResp'].bAllowShop);
  //       this.storeOrderNumber(parsed.['stAuthResp'].iOrderId);
  //       this.storelogedIn(parsed.['stAuthResp'].bLoggedIn)
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //       if(this.iUserType === 5){
  //         this.storeSalesUserCredentials(parsed.['stAuthResp'].sSessionID);
  //         this.storeSalesUserType(parsed.['stAuthResp'].iUserType);
          
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.data);
  //       error.data
  //     });
  // }

  // async guestNative(loginParams): Promise<HTTPResponse> {
  //   // let loading =  this.loadingCtrl.create();
  //   let headers = {
  //     "Content-Type": "application/json"
  //   };
  //   let sale = this.nativeHttp.post('https://red.msgrestservices.nl/Authorisation', {}, headers)
  //     .then(
  //       res => {
  //         let parsed = JSON.parse(res.data);
  //         this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //         console.log(parsed.['stAuthResp'].sSessionID)
  //         this.storeUserID(parsed.['stAuthResp'].iUserId);
  //         this.storeClientID(parsed.['stAuthResp'].iClient);
  //         this.storeUserType(parsed.['stAuthResp'].iUserType);
  //         this.storelogedIn(parsed.['stAuthResp'].bLoggedIn)
  //         // this.storeOrderNumber(parsed.['stAuthResp'].iOrderId);
  //         this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //         this.storeallowPBM(parsed.['stAuthResp'].bAllowPbm);
  //         console.log(parsed.['stAuthResp'].iPbmMode);
  //         console.log(parsed);
  //         this.userFullname(parsed.['stAuthResp'].sFullName);
  //       },
  //       error => error);
  //       console.log(sale)
  //       return sale
  // }

  // async GuestloginNative(loginParams) {
  //   // let loading =  this.loadingCtrl.create();
  //   return new Promise((resolve, reject) => {
  //     let headers = {
  //       'Content-Type': 'application/json'
  //     };
  //     this.nativeHttp.post('https://red.msgrestservices.nl/Authorisation', loginParams, headers)
  //       .then(
  //         res => resolve(res),
  //         error => reject(<any>error));
  //   });

  async SwitchloginNative() {
    // let loginParams = {
    //   "sUser":"12345MLAKKE",
    //   "sPass":"Ivana003",
    //   "iOrderType" : 104,
    //   "iUsId": 18, 
    //   "iPBMMode" : 0
    // }
    // this.nativeHttp.setDataSerializer("json");
    // console.log('native', loginParams)
    // let params = loginParams;
    // let loading =  this.loadingCtrl.create();
    let sha = CryptoJS.SHA512(window.localStorage.getItem('Authtoken')).toString(CryptoJS.enc.Base64);
    // let encode = CryptoJS.enc.Base64.stringify(sha)
    let encodedSource1 = sha.replace(/=+$/, '');
    let encodedSource2 = encodedSource1.replace(/\+/g, '-');
    let encodedSource3 = encodedSource2.replace(/\//g, '_');
    let part = encodedSource3.slice(4, 25);
    console.log('hash', sha);
    console.log('hash', encodedSource3);
    let string = JSON.stringify(window.localStorage.getItem('Authtoken'))

    const headers = {
      'Content-Type': 'application/json',
      'UserToken': `${window.localStorage.getItem("Authtoken")}`
    }
    console.log(headers);
    console.log(string)
    console.log('pbmlogin', window.localStorage.getItem('Authtoken'))
    console.log(part)
    this.http.get('https://red.msgrestservices.nl/Authorisation/' + part, {'headers':headers})
      .subscribe((data) => {
        let parsed = data;
        console.log('swtichdata',data)
        console.log('switchen data', data)
        // if(data.status === 201 || 401 || 403 || 404 || 204){
        //   if(this.CheckLoginUser()){
        //     console.log('loged in switch')
        //     let loginParams = {
        //       "sUser":window.localStorage.getItem("email"),
        //       "sPass":window.localStorage.getItem("password"),
        //       "iUsId": 0, 
        //       "iPBMMode" : 0
        //     }
        //     this.loginNative(loginParams);
        //   }else{
        //     console.log('guest switch')
        //     let loginParams = null
        //     this.guestNative(loginParams);
        //   }
        // }else{
          if(this.CheckLoginUser()){
        this.storeUserCredentials(parsed['stAuthResp'].sSessionID);
          }
        this.storeUserID(parsed['stAuthResp'].iUserId);
        this.storeUserType(parsed['stAuthResp'].iUserType);
        this.storePriceType(data['stAuthResp'].iPriceType);
        if(this.CheckLoginUser()){
          this.storeOrderNumber(parsed['stAuthResp'].iOrderId);
        }
        this.storeClientID(parsed['stAuthResp'].iClient);
        this.storePBM(parsed['stAuthResp'].iPbmMode);
        console.log(parsed['stAuthResp'].iPbmMode);
        console.log(parsed);
        this.userFullname(parsed['stAuthResp'].sFullName);
        // }
      },
      error => {
        console.log(error);
        console.log('swtichdata',error.status)
        if(error.status === 403){
          window.localStorage.clear();
          // if(this.CheckLoginUser()){
          //   console.log('loged in switch')
          //   let loginParams = {
          //     "sUser":window.localStorage.getItem("email"),
          //     "sPass":window.localStorage.getItem("password"),
          //     "iUsId": 0, 
          //     "iPBMMode" : 0
          //   }
          //   window.localStorage.setItem('checktokenloginUser', 'false')
          //   this.login(loginParams);
          // }else{
          //   console.log('guest switch')
          //   let loginParams = null
          //   this.Guestlogin();
          //   window.localStorage.setItem('checktokenloginUser', 'false')
          // }
        }
          // if(this.CheckLoginUser()){
          //   console.log('loged in switch')
          //   let loginParams = {
          //     "sUser":window.localStorage.getItem("email"),
          //     "sPass":window.localStorage.getItem("password"),
          //     "iUsId": 0, 
          //     "iPBMMode" : 0
          //   }
          //   window.localStorage.setItem('checktokenloginUser', 'false')
          //   this.loginNative(loginParams);
          // }else{
          //   console.log('guest switch')
          //   let loginParams = null
          //   this.guestNative(loginParams);
          //   window.localStorage.setItem('checktokenloginUser', 'false')
          // }
      },
      );
  }


    //     let headers = {
    //       'Content-Type': 'application/json'
    //     };
    //     this.nativeHttp.setDataSerializer("json");
    //     console.log('native', loginParams)
    //     let params = loginParams;
    // console.log(headers)
    // console.log( this.nativeHttp.post('https://red.msgrestservices.nl/Authorisation', loginParams, headers))
    //     this.nativeHttp.post('https://red.msgrestservices.nl/Authorisation', loginParams, headers)
    //     .then((data) => {
    //       let parsed = JSON.parse(data.data);
    //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
    //         this.storeUserID(parsed.['stAuthResp'].iUserId);
    //         this.storeClientID(parsed.['stAuthResp'].iClient);
    //         this.storeUserType(parsed.['stAuthResp'].iUserType);
    //         this.storePBM(parsed.['stAuthResp'].iPbmMode);
    //         this.storeallowPBM(parsed.['stAuthResp'].bAllowPbm);
    //         console.log(parsed.['stAuthResp'].iPbmMode);
    //         console.log(parsed);
    //         this.userFullname(parsed.['stAuthResp'].sFullName);
    //               })
    //               .catch((error) => {
    //                   console.log(error);
    //               });
  // }

  // guestlogin(data) {
  //   let parsed = JSON.parse(data.data);
  //   this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);

  //   this.storeUserID(parsed.['stAuthResp'].iUserId);
  //   this.storeClientID(parsed.['stAuthResp'].iClient);
  //   this.storeUserType(parsed.['stAuthResp'].iUserType);
  //   this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //   this.storeallowPBM(parsed.['stAuthResp'].bAllowPbm);
  //   console.log(parsed.['stAuthResp'].iPbmMode);
  //   console.log(parsed);
  //   this.userFullname(parsed.['stAuthResp'].sFullName);
  // }

  storeUserCredentials(token) {
    window.localStorage.setItem('Authtoken', token);
    console.log('test', token)
    this.useCredentials(token);
  }

  storeSalesUserCredentials(token) {
    window.localStorage.setItem('SalesAuthtoken', token);
    console.log('test', token)
    this.useSalesCredentials(token);
  }

  storeFullName(token) {
    window.localStorage.setItem('FullName', token);
    console.log('test', token)
    this.useFullName(token);
  }

  storePBMUserCredentials(token) {
    window.localStorage.setItem('PBMtoken', token);
    this.usePBMCredentials(token);
  }

  storePriceType(token) {
    window.localStorage.setItem('PriceType', token);
    this.usePriceType(token);
  }

  storeUserID(iUserId) {
    window.localStorage.setItem('iUserId', iUserId);
    this.useUserID(iUserId);
  }

  storeUserType(iUserType) {
    window.localStorage.setItem('iUserType', iUserType);
    this.useUserType(iUserType);
  }

  storeSalesUserType(iUserType) {
    window.localStorage.setItem('iSalesUserType', iUserType);
    this.useSalesUserType(iUserType);
  }

  storeClientID(iClient) {
    window.localStorage.setItem('iClient', iClient);
    this.useClientID(iClient);
  }

  storePBM(iPbmMode) {
    window.localStorage.setItem('iPbmMode', iPbmMode);
    this.usePBMmodeID(iPbmMode);
  }

  storeOrderNumber(OrderNumber) {
    window.localStorage.setItem('OrderNumber', OrderNumber);
    this.useOrderNumber(OrderNumber);
  }

  storePBMOrderNumber(OrderNumber) {
    window.localStorage.setItem('PBMOrderNumber', OrderNumber);
    this.usePBMOrderNumber(OrderNumber);
  }

  storeallowPBM(bAllowPbm) {
    window.localStorage.setItem('bAllowPbm', bAllowPbm);
    console.log(bAllowPbm)
    this.useallowPBM(bAllowPbm);
  }

  storelogedIn(bLoggedIn) {
    window.localStorage.setItem('bLoggedIn', bLoggedIn);
    console.log(bLoggedIn)
    this.useLogedIn(bLoggedIn);
  }

  storeallowSHOP(bAllowSHOP) {
    window.localStorage.setItem('bAllowSHOP', bAllowSHOP);
    console.log(bAllowSHOP)
    this.useallowSHOP(bAllowSHOP);
  }

  useCredentials(token) {
    this.isLoggedIn = true;
    this.AuthToken = token || window.localStorage.setItem('Authtoken', token);
  }

  useSalesCredentials(token) {
    this.isLoggedIn = true;
    this.SalesAuthToken= token || window.localStorage.setItem('SalesAuthtoken', token);
  }


  useFullName(token) {
    this.isLoggedIn = true;
    this.FullName= token || window.localStorage.setItem('FullName', token);
  }

  usePBMCredentials(token) {
    this.isLoggedIn = true;
    this.PBMAuthToken = token || window.localStorage.setItem('PBMAuthtoken', token);
  }

  useOrderNumber(OrderNumber) {
    this.isLoggedIn = true;
    this.OrderNumber = OrderNumber;
    console.log('authservice', this.OrderNumber)
  }

  usePBMOrderNumber(OrderNumber) {
    this.isLoggedIn = true;
    this.OrderNumber = OrderNumber;
    console.log('authservice', this.OrderNumber)
  }

  useUserID(iUserId) {
    this.isLoggedIn = true;
    this.iUserId = iUserId;
  }

  useUserType(iUserType) {
    this.isLoggedIn = true;
    this.iUserType = iUserType;
  }

  useSalesUserType(iUserType) {
    this.isLoggedIn = true;
    this.iSalesUserType = iUserType;
  }

  useClientID(iClient) {
    this.isLoggedIn = true;
    this.iClient = iClient;
  }

  usePBMmodeID(iPbmMode) {
    this.isLoggedIn = true;
    this.iPbmMode = iPbmMode;
  }

  useallowPBM(bAllowPbm) {
    this.isLoggedIn = true;
    this.bAllowPbm = bAllowPbm;
  }

  useallowSHOP(bAllowSHOP) {
    this.isLoggedIn = true;
    this.bAllowShop = bAllowSHOP;
  }

  useLogedIn(bLoggedIn) {
    this.isLoggedIn = true;
    this.bLoggedIn = bLoggedIn;
  }

  usePriceType(bLoggedIn) {
    this.isLoggedIn = true;
    this.iPriceType = bLoggedIn;
  }

  userFullname(fullname) {
    this.isLoggedIn = true;
    this.fullname = fullname;
    console.log(fullname)
  }

  loadUserCredentials() {
    let token = window.localStorage.getItem('Authtoken');
    this.AuthToken = window.localStorage.getItem('Authtoken');
    this.SalesAuthToken = window.localStorage.getItem('SalesAuthToken');
    this.OrderNumber = window.localStorage.getItem('OrderNumber');
    this.iUserId = window.localStorage.getItem('iUserId');
    this.iUserType = window.localStorage.getItem('iUserType');
    this.bAllowPbm = window.localStorage.getItem('bAllowPbm');
    this.iClient = window.localStorage.getItem('iClient');
    this.iPbmMode = window.localStorage.getItem('iPbmMode');
    this.iSalesUserType = window.localStorage.getItem('iSalesUserType');
    console.log('check to login', this.iSalesUserType)
    this.useCredentials(token);
  }

  destroyUserCredentials() {
    this.isLoggedIn = false;
    this.AuthToken = null;
    window.localStorage.removeItem('bLoggedIn');
    window.localStorage.removeItem('Authtoken')
    window.localStorage.removeItem('iSalesUserType');
    window.localStorage.setItem('OrderNumber', "0");
  }

  createAuthorization(headers: Headers) {
    headers.append('UserToken', window.localStorage.getItem('Authtoken'));
  }

  // async PBMloginNative() {
  //   // let loading =  this.loadingCtrl.create();
  //   let sha = CryptoJS.SHA512(window.localStorage.getItem('Authtoken')).toString(CryptoJS.enc.Base64);
  //   // let encode = CryptoJS.enc.Base64.stringify(sha)
  //   let encodedSource1 = sha.replace(/=+$/, '');
  //   let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //   let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //   let part = encodedSource3.slice(4, 25);
  //   console.log('hash', sha);
  //   console.log('hash', encodedSource3);
  //   let string = JSON.stringify(window.localStorage.getItem('Authtoken'))

  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   console.log(headers);
  //   console.log(string)
  //   console.log('pbmlogin', window.localStorage.getItem('Authtoken'))
  //   this.nativeHttp.get('https://red.msgrestservices.nl/Authorisation/' + part + '?SetPBM=On', {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storePBMUserCredentials(parsed.['stAuthResp'].sSessionID);
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       this.storePBMOrderNumber(parsed.['stAuthResp'].iOrderId);
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
    // let nativeCall = this.nativeHttp.post('https://red.msgrestservices.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
    // console.log('this is native');
    // from(nativeCall).pipe(
    //   finalize(() => console.log('ok'))
    // )
    // .subscribe(data => {

    //   let parsed = JSON.parse(data.data);
    //   // this.auth = parsed.['stAuthResp'];
    //   // console.log(this.promo);
    //   console.log(data);
    //   if(parsed.['stAuthResp']){
    //     this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
    //     this.storeUserID(parsed.['stAuthResp'].iUserId);
    //     this.storePBM(parsed.['stAuthResp'].iPbmMode);
    //     console.log(parsed.['stAuthResp'].iPbmMode);
    //     console.log(parsed);
    //     this.userFullname(parsed.['stAuthResp'].sFullName);
    //     // resolve(true);
    //   }
    // },
    //  err => {
    //   console.log('native error : ', err);
    // });
  // }

  // async SwitchloginNative() {
  //   // let loginParams = {
  //   //   "sUser":"12345MLAKKE",
  //   //   "sPass":"Ivana003",
  //   //   "iOrderType" : 104,
  //   //   "iUsId": 18, 
  //   //   "iPBMMode" : 0
  //   // }
  //   this.nativeHttp.setDataSerializer("json");
  //   // console.log('native', loginParams)
  //   // let params = loginParams;
  //   // let loading =  this.loadingCtrl.create();
  //   let sha = CryptoJS.SHA512(window.localStorage.getItem('Authtoken')).toString(CryptoJS.enc.Base64);
  //   // let encode = CryptoJS.enc.Base64.stringify(sha)
  //   let encodedSource1 = sha.replace(/=+$/, '');
  //   let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //   let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //   let part = encodedSource3.slice(4, 25);
  //   console.log('hash', sha);
  //   console.log('hash', encodedSource3);
  //   let string = JSON.stringify(window.localStorage.getItem('Authtoken'))

  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('Authtoken')}`
  //   };
  //   console.log(headers);
  //   console.log(string)
  //   console.log('pbmlogin', window.localStorage.getItem('Authtoken'))
  //   console.log(part)
  //   this.nativeHttp.get('https://red.msgrestservices.nl/Authorisation/' + part, {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       console.log('swtichdata',data.status)
  //       console.log('switchen data', data)
  //       // if(data.status === 201 || 401 || 403 || 404 || 204){
  //       //   if(this.CheckLoginUser()){
  //       //     console.log('loged in switch')
  //       //     let loginParams = {
  //       //       "sUser":window.localStorage.getItem("email"),
  //       //       "sPass":window.localStorage.getItem("password"),
  //       //       "iUsId": 0, 
  //       //       "iPBMMode" : 0
  //       //     }
  //       //     this.loginNative(loginParams);
  //       //   }else{
  //       //     console.log('guest switch')
  //       //     let loginParams = null
  //       //     this.guestNative(loginParams);
  //       //   }
  //       // }else{
  //         if(this.CheckLoginUser()){
  //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //         }
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       if(this.CheckLoginUser()){
  //         this.storeOrderNumber(parsed.['stAuthResp'].iOrderId);
  //       }
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log('swtichdata',error.status)
  //       if(error.status === 400){
  //         if(this.CheckLoginUser()){
  //           console.log('loged in switch')
  //           let loginParams = {
  //             "sUser":window.localStorage.getItem("email"),
  //             "sPass":window.localStorage.getItem("password"),
  //             "iUsId": 0, 
  //             "iPBMMode" : 0
  //           }
  //           window.localStorage.setItem('checktokenloginUser', 'false')
  //           this.loginNative(loginParams);
  //         }else{
  //           console.log('guest switch')
  //           let loginParams = null
  //           this.guestNative(loginParams);
  //           window.localStorage.setItem('checktokenloginUser', 'false')
  //         }
  //       }
  //         // if(this.CheckLoginUser()){
  //         //   console.log('loged in switch')
  //         //   let loginParams = {
  //         //     "sUser":window.localStorage.getItem("email"),
  //         //     "sPass":window.localStorage.getItem("password"),
  //         //     "iUsId": 0, 
  //         //     "iPBMMode" : 0
  //         //   }
  //         //   window.localStorage.setItem('checktokenloginUser', 'false')
  //         //   this.loginNative(loginParams);
  //         // }else{
  //         //   console.log('guest switch')
  //         //   let loginParams = null
  //         //   this.guestNative(loginParams);
  //         //   window.localStorage.setItem('checktokenloginUser', 'false')
  //         // }
  //     });
  //   // let nativeCall = this.nativeHttp.post('https://red.msgrestservices.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
  //   // console.log('this is native');
  //   // from(nativeCall).pipe(
  //   //   finalize(() => console.log('ok'))
  //   // )
  //   // .subscribe(data => {

  //   //   let parsed = JSON.parse(data.data);
  //   //   // this.auth = parsed.['stAuthResp'];
  //   //   // console.log(this.promo);
  //   //   console.log(data);
  //   //   if(parsed.['stAuthResp']){
  //   //     this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //   //     this.storeUserID(parsed.['stAuthResp'].iUserId);
  //   //     this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed);
  //   //     this.userFullname(parsed.['stAuthResp'].sFullName);
  //   //     // resolve(true);
  //   //   }
  //   // },
  //   //  err => {
  //   //   console.log('native error : ', err);
  //   // });
  // }

  // async SwitchClientSalesNative() {
  //   // let loginParams = {
  //   //   "sUser":"12345MLAKKE",
  //   //   "sPass":"Ivana003",
  //   //   "iOrderType" : 104,
  //   //   "iUsId": 18, 
  //   //   "iPBMMode" : 0
  //   // }
  //   this.nativeHttp.setDataSerializer("json");
  //   // console.log('native', loginParams)
  //   // let params = loginParams;
  //   // let loading =  this.loadingCtrl.create();
  //   let sha = CryptoJS.SHA512(window.localStorage.getItem('SalesAuthtoken')).toString(CryptoJS.enc.Base64);
  //   // let encode = CryptoJS.enc.Base64.stringify(sha)
  //   let encodedSource1 = sha.replace(/=+$/, '');
  //   let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //   let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //   let part = encodedSource3.slice(4, 25);
  //   console.log('hash', sha);
  //   console.log('hash', encodedSource3);
  //   let string = JSON.stringify(window.localStorage.getItem('SalesAuthtoken'))

  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('SalesAuthtoken')}`
  //   };
  //   console.log(headers);
  //   console.log(string)
  //   console.log('pbmlogin', window.localStorage.getItem('SalesAuthtoken'))
  //   console.log(part)
  //   this.nativeHttp.get('https://red.msgrestservices.nl/Authorisation/' + part, {}, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       console.log('swtichdata',data.status)
  //       console.log('switchen data', data)
  //       // if(data.status === 201 || 401 || 403 || 404 || 204){
  //       //   if(this.CheckLoginUser()){
  //       //     console.log('loged in switch')
  //       //     let loginParams = {
  //       //       "sUser":window.localStorage.getItem("email"),
  //       //       "sPass":window.localStorage.getItem("password"),
  //       //       "iUsId": 0, 
  //       //       "iPBMMode" : 0
  //       //     }
  //       //     this.loginNative(loginParams);
  //       //   }else{
  //       //     console.log('guest switch')
  //       //     let loginParams = null
  //       //     this.guestNative(loginParams);
  //       //   }
  //       // }else{
  //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       this.storeOrderNumber(parsed.['stAuthResp'].iOrderId);
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log('swtichdata',error.status)
  //       if(error.status === 400){
  //         if(this.CheckLoginUser()){
  //           console.log('loged in switch')
  //           let loginParams = {
  //             "sUser":window.localStorage.getItem("email"),
  //             "sPass":window.localStorage.getItem("password"),
  //             "iUsId": 0, 
  //             "iPBMMode" : 0
  //           }
  //           window.localStorage.setItem('checktokenloginUser', 'false')
  //           this.loginNative(loginParams);
  //         }else{
  //           console.log('guest switch')
  //           let loginParams = null
  //           this.guestNative(loginParams);
  //           window.localStorage.setItem('checktokenloginUser', 'false')
  //         }
  //       }
  //         // if(this.CheckLoginUser()){
  //         //   console.log('loged in switch')
  //         //   let loginParams = {
  //         //     "sUser":window.localStorage.getItem("email"),
  //         //     "sPass":window.localStorage.getItem("password"),
  //         //     "iUsId": 0, 
  //         //     "iPBMMode" : 0
  //         //   }
  //         //   window.localStorage.setItem('checktokenloginUser', 'false')
  //         //   this.loginNative(loginParams);
  //         // }else{
  //         //   console.log('guest switch')
  //         //   let loginParams = null
  //         //   this.guestNative(loginParams);
  //         //   window.localStorage.setItem('checktokenloginUser', 'false')
  //         // }
  //     });
  //   // let nativeCall = this.nativeHttp.post('https://red.msgrestservices.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
  //   // console.log('this is native');
  //   // from(nativeCall).pipe(
  //   //   finalize(() => console.log('ok'))
  //   // )
  //   // .subscribe(data => {

  //   //   let parsed = JSON.parse(data.data);
  //   //   // this.auth = parsed.['stAuthResp'];
  //   //   // console.log(this.promo);
  //   //   console.log(data);
  //   //   if(parsed.['stAuthResp']){
  //   //     this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //   //     this.storeUserID(parsed.['stAuthResp'].iUserId);
  //   //     this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed);
  //   //     this.userFullname(parsed.['stAuthResp'].sFullName);
  //   //     // resolve(true);
  //   //   }
  //   // },
  //   //  err => {
  //   //   console.log('native error : ', err);
  //   // });
  // }
  // async Clientlogin(loginparams) {
  //   return new Promise((resolve, reject) => {
  //     var headers = new Headers();
  //     let options = new RequestOptions({ headers: headers, withCredentials: true });
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     headers.append('UserToken', window.localStorage.getItem('SalesAuthtoken'))
  //     console.log('saleslogin', window.localStorage.getItem('SalesAuthtoken'))
  //     // var b64 = CryptoJS.AES.encrypt(plainText, SECRET).toString();
  //     let sha = CryptoJS.SHA512(window.localStorage.getItem('SalesAuthtoken')).toString(CryptoJS.enc.Base64);
  //     // let encode = CryptoJS.enc.Base64.stringify(sha)
  //     let encodedSource1 = sha.replace(/=+$/, '');
  //     let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //     let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //     let part = encodedSource3.slice(4, 25);
  //     console.log('hash', sha);
  //     console.log('hash', encodedSource3);
  //     // console.log('hash', encode);
  //     let url = '/Authorisation/'+part;
  //     console.log(url)
  //     console.log('clientservice', JSON.stringify(loginparams))
  //     let user = this.http.put(url, loginparams, options)
  //       .subscribe(data => {
  //         console.log(data);
  //         if (data.['stAuthResp']) {
  //           this.storeUserCredentials(data.['stAuthResp'].sSessionID);
  //           this.storeUserID(data.['stAuthResp'].iUserId);
  //           this.storeClientID(data.['stAuthResp'].iClient);
  //           this.storeUserType(data.['stAuthResp'].iUserType);
  //           // this.storage.set('userToken', this.AuthToken);
  //           // console.log(this.AuthToken);
  //           this.storePBM(data.['stAuthResp'].iPbmMode);
  //           console.log(data.['stAuthResp'].iPbmMode);
  //           console.log(data.['stAuthResp'].iClient);
  //           this.userFullname(data.['stAuthResp'].sFullName);
  //           resolve(true);
  //         }
  //         else
  //           resolve(false);
  //       });
  //   });
  // }

  // async ClientloginNative(loginparams) {
  //   // let loading =  this.loadingCtrl.create();
  //   let sha = CryptoJS.SHA512(window.localStorage.getItem('SalesAuthtoken')).toString(CryptoJS.enc.Base64);
  //   // let encode = CryptoJS.enc.Base64.stringify(sha)
  //   let encodedSource1 = sha.replace(/=+$/, '');
  //   let encodedSource2 = encodedSource1.replace(/\+/g, '-');
  //   let encodedSource3 = encodedSource2.replace(/\//g, '_');
  //   let part = encodedSource3.slice(4, 25);
  //   console.log('hash', sha);
  //   console.log('hash', encodedSource3);
  //   let string = JSON.stringify(window.localStorage.getItem('SalesAuthtoken'))

  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem('SalesAuthtoken')}`
  //   };
  //   this.nativeHttp.setDataSerializer("json");
  //   console.log('native', loginparams)
  //   let params = loginparams;
  //   console.log(headers);
  //   console.log(string)
  //   console.log('pbmlogin', window.localStorage.getItem('SalesAuthtoken'))
  //   this.nativeHttp.put('https://red.msgrestservices.nl/Authorisation/' + part, params, headers)
  //     .then((data) => {
  //       let parsed = JSON.parse(data.data);
  //       this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //       this.storeUserID(parsed.['stAuthResp'].iUserId);
  //       this.storeClientID(parsed.['stAuthResp'].iClient);
  //       this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //       this.storeUserType(parsed.['stAuthResp'].iUserType);
  //       this.storeallowPBM(parsed.['stAuthResp'].bAllowPbm);
  //       this.storeOrderNumber(parsed.['stAuthResp'].iOrderId);
  //       this.storelogedIn(parsed.['stAuthResp'].bLoggedIn)
  //       console.log(parsed.['stAuthResp'].iPbmMode);
  //       console.log(parsed);
  //       this.userFullname(parsed.['stAuthResp'].sFullName);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // let nativeCall = this.nativeHttp.post('https://red.msgrestservices.nl/Authentication/'+part+'?SetPBM=On',{}, headers)
  //   // console.log('this is native');
  //   // from(nativeCall).pipe(
  //   //   finalize(() => console.log('ok'))
  //   // )
  //   // .subscribe(data => {

  //   //   let parsed = JSON.parse(data.data);
  //   //   // this.auth = parsed.['stAuthResp'];
  //   //   // console.log(this.promo);
  //   //   console.log(data);
  //   //   if(parsed.['stAuthResp']){
  //   //     this.storeUserCredentials(parsed.['stAuthResp'].sSessionID);
  //   //     this.storeUserID(parsed.['stAuthResp'].iUserId);
  //   //     this.storePBM(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed.['stAuthResp'].iPbmMode);
  //   //     console.log(parsed);
  //   //     this.userFullname(parsed.['stAuthResp'].sFullName);
  //   //     // resolve(true);
  //   //   }
  //   // },
  //   //  err => {
  //   //   console.log('native error : ', err);
  //   // });
  // }
  

}