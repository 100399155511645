import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.sass']
})
export class ThanksComponent implements OnInit {
  public params: any = {};
  storderlines: any;
  order:any;
  orderstate:any;
  constructor(private cartService: CartService,private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {
    this.getOrder();
  }

  async getOrder() {
    this.spinner.show(); 
      this.cartService.getOrder()
        .subscribe(data => {
          // let parsed = JSON.parse(data.data);
          this.spinner.hide();
          this.order = data['stOrderState'].iOrderNumber;
          this.orderstate = data['stOrderState'].iOrderState;
          window.localStorage.setItem('OrderState', data['stOrderState'].iOrderState);
          this.storderlines = data['stOrderLines'];
          // this.deliverycost = parsed.stOrderLines.arrOrderKost;
          // this.user = parsed.stOrder;
          // const sOrderCorr = this.user.sOrderCorr;
          // const sContactTel = this.user.sContactTel;
          // const sContactMail = this.user.sContactMail;

          // this.categories = res['stResult']['stNavMenu']['arrMenuGroup'];
          // loading.dismiss();
        }, err => {
          // console.log(err);
          // console.log('order error Forbidden', err.status)
          // if (this.authService.checkGuestLogin()) {
          //   if (err.status === 401 || err.status === 403 || err.status === 400) {
          //     // this.presentAlertGuest();
          //     this.navigateTo('/1/home')
          //   }
          // } else {
          //   if (err.status === 401 || err.status === 403 || err.status === 400) {
          //     // this.presentAlert();
          //     this.navigateTo('/login')
          //   }
          // }
          // loading.dismiss();
        });
    // } else {
    //   this.cartService.getOrder()
    //     .subscribe(res => {
    //       console.log('order', res['stOrderPayment']);
    //       this.order = res['stOrderPayment'];
    //       // this.user = res['stOrder'];
    //       // console.log(this.user);
    //       console.log(this.order['sIdealPayURL']);
    //       // loading.dismiss();
    //     }, err => {
    //       console.log(err);
    //       // loading.dismiss();
    //     });
    // }
  }

  async goToHome() {
    this.router.navigate(['/']);
    window.localStorage.removeItem('red_cart'+`${window.localStorage.getItem('OrderNumber')}`)
    // console.log(this.orderstate)
    // if(this.orderstate = 85){

    // }else if (this.orderstate = 9){
      // console.log('removeordernumber');
      window.localStorage.removeItem('OrderNumber');
    // }else {

    // }

  }

  async goToPBMHome() {
    this.router.navigate(['/']);
    window.localStorage.removeItem('red_pbm_cart'+`${window.localStorage.getItem('PBMOrderNumber')}`)
    window.localStorage.removeItem('PBMOrderNumber');
  }

}
