import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
// import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { map } from 'rxjs/operators';
import { AuthService } from '.././services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getPromoSlider(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let promo = this.http.get('https://red.msgrestservices.nl/Promotion', { headers: headers });
    console.log('search', promo);
    return promo;
  }

  // getPromoNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json'
  //   };
  //   let promo = this.nativeHttp.get('https://red.msgrestservices.nl/Promotion', {}, headers);
  //   console.log('promo', promo);
  //   console.log('this is native');
  //   return promo;
  // }

  getSale(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let sale = this.http.get('https://red.msgrestservices.nl/Promotion', { headers: headers });
    console.log('search', sale);
    return sale;
  }

  // getSaleNative(): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json'
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Promotion', {}, headers);
  //   console.log('sale', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getProductsByCategory(category): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Request-Headers': '*',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    // headers.append('UserToken', window.localStorage.getItem("Authtoken"));
    let items = this.http.get('https://red.msgrestservices.nl/Article?Category=' + category, {'headers':headers});
    console.log('products', items);
    return items;
  }

  // getProductsByNative(category): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article?Category='+category, {}, headers);
  //   console.log('products category', items);
  //   console.log('this is native');
  //   return items;
  // }

  getProductsByCategoryFilter(category, filter): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    headers.append('UserToken', window.localStorage.getItem("Authtoken"));
    let items = this.http.get('https://red.msgrestservices.nl/Article?Category=' + category + filter, { headers: headers });
    console.log(filter)
    console.log('products', items);
    return items;
  }

  // getProductsByNativeFilter(category, filter): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(headers);
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article?Category='+category+filter, {}, headers);
  //   console.log('products category', items);
  //   console.log('this is native');
  //   return items;
  // }

  // getProductsByNativeFilter(category, filter): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     'UserToken': `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log(headers);
  //   console.log('https://red.msgrestservices.nl/Article?Category='+category+filter)
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article?Category='+category+filter, {}, headers);
  //   console.log('products category', items);
  //   console.log('this is native');
  //   return items;
  // }

  getProductsByCategorySearch(item): Observable<any> {
    // let headers = new HttpHeaders();
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    // headers.append('Accept', 'application/json');
    // headers.append('content-type', 'application/json');
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Request-Headers': '*',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    // headers.append('UserToken', window.localStorage.getItem("Authtoken"));
    let items = this.http.get('https://red.msgrestservices.nl/Article?SearchValue=' + item, {'headers':headers});
    console.log('products', items);
    return items;
  }

  // getProductsByNativeSearch(item): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json'
  //   };
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article?SearchValue='+item, {}, headers);
  //   console.log('products category', items);
  //   console.log('this is native');
  //   return items;
  // }

  getProductsByDetail(detail): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Request-Headers': '*',
      "UserToken": `${window.localStorage.getItem("Authtoken")}`
    }
    // headers.append('UserToken', window.localStorage.getItem("Authtoken"));
    let items = this.http.get('https://red.msgrestservices.nl/Article/' + detail, {'headers':headers});
    console.log('products', items);
    return items;
  }

  getGTNProductsByDetail(detail): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    // headers.append('UserToken', window.localStorage.getItem("Authtoken"));
    let items = this.http.get('https://red.msgrestservices.nl/Article/'+detail+'?Barcode='+detail, { headers: headers });
    console.log('products', items);
    return items;
  }

  // getProductsByDetailNative(detail, key): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article/' + detail + '?BlKey=' + key, {}, headers);
  //   console.log('detail', items);
  //   console.log('itemcheck', detail, key)
  //   console.log('this is native');
  //   return items;
  // }

  // getGTNProductsByDetailNative(detail): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let items = this.nativeHttp.get('https://red.msgrestservices.nl/Article/'+detail+'?Barcode='+detail, {}, headers);
  //   console.log('detail', items);
  //   console.log('itemcheck', detail)
  //   console.log('this is native');
  //   return items;
  // }


  searchData(suggestion: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    let suggestions = this.http.get(`https://red.msgrestservices.nl/Searchword/Suggestion/` + suggestion, { headers: headers });
    console.log('suggestion', suggestions);
    return suggestions;
  }

  // searchDataNative(suggestion: any): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json'
  //   };
  //   let suggestions = this.nativeHttp.get('https://red.msgrestservices.nl/Searchword/Suggestion/' + suggestion, {}, headers);
  //   console.log('search', suggestions);
  //   console.log('this is native');
  //   return suggestions;
  // }

  getSearchPBMOrderlistItem(search: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("PBMtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Orderlist/0?searchvalue=' + search, { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getSearchPBMOrderlistItemnative(search: any, category): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     'UserToken': `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   console.log(headers)
  //   console.log('searchlist', category)
  //   console.log('searchlist', search)
  //   console.log('https://red.msgrestservices.nl/Orderlist/'+category+'?searchvalue='+search+'')
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Orderlist/'+category+'?searchvalue='+search, {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getOrderlist(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Orderlist', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getOrderlistnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log('check bestellijst token', headers)
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Orderlist', {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getPBMlist(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("PBMtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(window.localStorage.getItem("PBMtoken"))
    console.log(window.localStorage.getItem("Authtoken"))
    let sale = this.http.get('https://red.msgrestservices.nl/Orderlist', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getPBMlistnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Orderlist', {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getPBMPoints(id: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('getPBMpoints', id)
    console.log(headers.append('usertoken', window.localStorage.getItem("PBMtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/PBM/Points/' + id, { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getPBMPointsnative(id: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/PBM/Points/' + id, {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getOrderlistItem(item: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Orderlist/' + item, { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getOrderlistItemnative(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Orderlist/' + item, {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getPBMOrderlistItem(item: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("PBMtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("PBMtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Orderlist/' + item, { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getPBMOrderlistItemnative(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("PBMtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Orderlist/' + item, {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getClient(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));;
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('UserToken', window.localStorage.getItem("Authtoken"))
    let sale = this.http.get('https://red.msgrestservices.nl/Client', { headers: headers });
    console.log('client', sale);
    return sale;
  }


  // getClientnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Client', {}, headers);
  //   console.log('client', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getSalesClient(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));;
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('UserToken', window.localStorage.getItem("SalesAuthtoken"))
    let sale = this.http.get('/Client', { headers: headers });
    console.log('client', sale);
    return sale;
  }


  // getSalesClientnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("SalesAuthtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Client', {}, headers);
  //   console.log('client', sale);
  //   console.log('this is native');
  //   return sale;
  // }


  postOrder(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));;
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log('UserToken', window.localStorage.getItem("Authtoken"))
    let sale = this.http.post('https://red.msgrestservices.nl/Order', { headers: headers });
    console.log('order', sale);
    return sale;
  }


  // postOrdernative(): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://red.msgrestservices.nl/Order', {}, headers);
  //   console.log('order', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  postOrderLines(item: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));;
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    headers.append('UserToken', window.localStorage.getItem("Authtoken"))
    console.log('UserToken', window.localStorage.getItem("Authtoken"))
    console.log('jsoncart', item)
    console.log('ordernumber', window.localStorage.getItem("OrderNumber"))
    let sale = this.http.post('https://red.msgrestservices.nl/OrderLine/' + window.localStorage.getItem("OrderNumber"), item, { headers: headers });
    console.log('order', sale);
    return sale;
  }


  // postOrderLinesnative(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.post('https://red.msgrestservices.nl/OrderLine/' + window.localStorage.getItem("OrderNumber"), item, headers);
  //   console.log('order', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getUser(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/User/' + window.localStorage.getItem("iUserId"), { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getUsernative(): Promise<HTTPResponse> {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/User/' + window.localStorage.getItem("iUserId"), {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getActionlist(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Action', {headers:headers});
    console.log('orderlist', sale);
    return sale;
  }


  // getActionListnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json"
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Action',{},headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getActionlistItem(item: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    console.log('listname', encodeURIComponent('PUR STAFFEL'))
    let sale = this.http.get('https://red.msgrestservices.nl/Action/${' + encodeURI(item) + '}', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getActionItemnative(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   console.log('https://red.msgrestservices.nl/Action/'+`${encodeURI(item)}`)
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Action/'+`${encodeURI(item)}`, {}, headers);
  //   console.log('nativeencode', item)
  //   console.log('nativeencode2', encodeURI(item))
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getClientAddresses(item: any): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    console.log('listname', encodeURIComponent(item))
    let sale = this.http.get('https://red.msgrestservices.nl/Client/' + item + '/Addresses', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getClientAddressesnative(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Client/' + item + '/Addresses', {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getClientInfo(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    // console.log('listname', encodeURIComponent(item))
    let sale = this.http.get('https://red.msgrestservices.nl/Client/' + window.localStorage.getItem("iClient"), { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getClientInfonative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Client/' + window.localStorage.getItem("iClient"), {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getStores(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Store', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getStoresnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Store', {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getDeliveryCost(): Observable<any> {
    let headers = new HttpHeaders().set('UserToken', window.localStorage.getItem("Authtoken"));
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/Delivery/Cost', { headers: headers });
    console.log('orderlist', sale);
    return sale;
  }


  // getDeliveryCostnative(): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     "UserToken": `${window.localStorage.getItem("Authtoken")}`
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/Delivery/Cost', {}, headers);
  //   console.log('orderlist', sale);
  //   console.log('this is native');
  //   return sale;
  // }

  getBreadCrumb(item: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    console.log(headers.append('usertoken', window.localStorage.getItem("Authtoken")))
    let sale = this.http.get('https://red.msgrestservices.nl/BreadCrumb?ArticleID=' + item, { headers: headers });
    console.log('breadcrumb', sale);
    return sale;
  }


  // getBreadCrumbNatie(item: any): Promise<HTTPResponse> {
  //   let headers = {
  //     "Content-Type": "application/json"
  //   };
  //   let sale = this.nativeHttp.get('https://red.msgrestservices.nl/BreadCrumb?Category=' + item, {}, headers);
  //   console.log('breadcrumb', sale);
  //   console.log('this is native');
  //   return sale;
  // }


  //   getProductsByCategory(category) {
  //     let loading =  this.loadingCtrl.create();
  //     if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
  //       // Return the cached data from Storage
  //       return from(this.getLocalData('users'));
  //     } else {
  //     let headers = new HttpHeaders();
  //     headers.append('Access-Control-Allow-Origin' , '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept','application/json');
  //     headers.append('content-type','application/json');
  //     headers.append('UserToken', window.localStorage.getItem("Authtoken"));
  //     console.log(window.localStorage.getItem("Authtoken"));
  //     let url = '/Article?Category='+category; 
  //     let req = this.http.get(url, {headers:headers}).pipe(
  //       finalize(() => console.log('ok'))
  //     )
  //     .subscribe(data => {
  //       this.products = data['stArticles']['arrArticleInList'];
  //       console.log(data['stArticles']['arrArticleInList']);
  //       console.log(this.products);
  //         let ttl = 10;
  //         this.products = this.cache.loadFromObservable(url, req, this.productsKey, ttl);
  //         this.products =  this.cache.loadFromObservable(url, req, this.productsKey);
  //         // console.log('ok');
  //       this.setLocalData('category', data);
  //       return this.products
  //     }, err => {
  //       console.log('JS Call error: ', err);
  //     });
  //   }
  // }
}
