import { Component, OnInit, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartService } from '../../../../services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { ProductService } from '../../../../services/product.service';
import { AuthService } from '../../../../services/auth.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailsComponent implements OnInit {
  public params: any = {};
  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  shopimagesmedium = "https://msgcms.msgnl.com/msgcms_web/RED/ShopImages/"
  default= "./assets/img/default-img.png"
  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public product            :   Product = {};
  public products           :   Product[] = [];

  public image: any;
  public zoomImage: any;

  public counter            :   number = 1;

  index: number;
  productid:any;
  blartkey: any;
  orderlines:any;
  orderline:any;
  breadcrumb:any;
  bigProductImageIndex = 0;
  item:any;
  items:any;
  properties:any;
  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, public authService: AuthService, public productService: ProductService, public dialog: MatDialog, private router: Router, private cartService: CartService) {
    // this.route.params.subscribe(params => {
    //   const id = +params['id'];
    //   console.log('productid',id)
    //   this.getSingleProduct(id)
    //   // this.productsService.getProduct(id).subscribe(product => this.product = product)
    // });
    this.spinner.show();  
   }

  ngOnInit() {
    this.productid = this.route.snapshot.params.id;
    this.params.id = this.productid
    // console.log(this.product)

    this.getSingleProduct(this.productid)
    this.getBreadCrumb(this.productid)
    // this.productsService.getProducts().subscribe(product => this.products = product);

 
    // this.getRelatedProducts();
  }

  async getSingleProduct(item:any) {

    this.productService.getProductsByDetail(item)
      .subscribe(res => {
        // console.log('item', res);
        if(res){
      
        this.item = res['stArticle'];
        this.items = res['stRelated']['arrArticleInList'];
        this.properties = res['stArticle']['stProperties']['arrProp'];
        }
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
      // this.spinner.hide();
  }

  async getBreadCrumb(item:any) {
    this.productService.getBreadCrumb(item)
      .subscribe(res => {
        // console.log('item', res);
        this.breadcrumb = res['stNavMenu']['arrMenuGroup'][0].sGroupDesc;
        this.spinner.hide();
        // loading.dismiss();
      }, err => {
        console.log(err);
        // loading.dismiss();
      });
  }




  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },


      }
    }
  }


  public openProductDialog(product, bigProductImageIndex) {
    let dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {product, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }


  public selectImage(index) {
    // console.log(this.product)
    // console.log(index)
    this.bigProductImageIndex = index;
  }




public increment() {
  this.counter += 1;
}

public decrement() {
  if(this.counter >1){
     this.counter -= 1;
  }
}

// getRelatedProducts() {
//   this.productsService.getProducts()
//   .subscribe(
//     (product: Product[]) => {
//       this.products = product
//     });
// }

  // Add to cart
  // public addToCart(product: Product, quantity) {
  //   if (quantity == 0) return false;
  //   this.cartService.addToCart(product, parseInt(quantity));
  // }

   // Add to cart
//    public buyNow(product: Product, quantity) {
//     if (quantity > 0)
//       this.cartService.addToCart(product,parseInt(quantity));
//       this.router.navigate(['/pages/checkout']);
//  }

onEnter(item){
  this.addToCart(item);
}


 addToCart(item) {
  // var newvalue	= Math.floor(item.nQuantity/parseFloat(item.nOrderfactor)) * parseFloat(item.nOrderfactor);
  this.spinner.show();  
  if(item.nOrderFactor >= 0 ){
    // this.presentLoading();
    if(this.authService.checkOrderNumber()){
      this.postOrder();
    }else{
      if(window.localStorage.getItem('OrderNumber') > "0"){
      this.getOrderLines();
      }
    }
  // console.log(item);
  setTimeout(() => {
    if(item.nPriceFactor > 1){
      if(item.nQuantity === 0 || item.nQuantity === '' || item.nQuantity === 'undefined' || item.nQuantity === 'NaN'){
      var productPrice = 1 * parseFloat(item.stPrice.curNetPriceInc) / item.nPriceFactor;
      }else{
        var productPrice = item.nQuantity * parseFloat(item.stPrice.curNetPriceInc) / item.nPriceFactor;
      }
    }else{
      if(item.nQuantity === 0 || item.nQuantity === '' || item.nQuantity === 'undefined' || item.nQuantity === 'NaN'){
        var productPrice = 1 * parseFloat(item.stPrice.curNetPriceInc) 
      }else{
        var productPrice = item.nQuantity * parseFloat(item.stPrice.curNetPriceInc) 
      }
    }
    if(item.nQuantity === 0 || item.nQuantity === '' || item.nQuantity === 'undefined' || item.nQuantity === 'NaN'){
    let cartProduct = {
      product_id: item.sArticleCode,
      sDescription1: item.sDescription1,
      sArticleBrand: item.sArticleBrand,
      sPromoImage: item.sPromoImage,
      sArticleCode: item.sArticleCode,
      sBlArtKey: item.sBlArtKey,
      nQuantityStep: item.nQuantityStep,
      nPriceFactor: item.nPriceFactor,
      curNetPrice: item.stPrice.curNetPrice,
      curNetPriceInc: item.stPrice.curNetPriceInc,
      count: 1,
      iNbPoints: item.iNbPoints,
      totalPrice: productPrice
    };
    let cartProductRest =       {
      "sArticleCode":item.sArticleCode,
      "sBlArtKey":this.blartkey,
      "nAmount": 1, 
    };
    console.log(this.orderlines)
      if(!this.orderlines){
        console.log('New item in order')
        // this.cartService.addToCart(cartProduct);
        console.log(cartProductRest)
          this.cartService.postOrderLine(cartProductRest).subscribe(res => {
            console.log(res)
            this.spinner.hide();
            if(res.status === 401){
              this.postOrder();
            }
          });
      }else{
      console.log(this.orderlines.find(items => items.sArticleCode === item.sArticleCode))
        if(this.orderlines.find(items => items.sArticleCode === item.sArticleCode)){
          console.log('item already in order')
          let sale = this.orderlines.find(items => items.sArticleCode === item.sArticleCode)
          this.orderline = sale
          // console.log(this.orderline.sArticleCode)
          // console.log(this.orderline.iLineID)
          // console.log(this.orderline.nAmount + item.nQuantity)
          let quantity = this.orderline.nAmount + item.nQuantity
          let index = this.orderline.iLineID;
          let cartProductRest =       {
            "sArticleCode":item.sArticleCode,
            "sBlArtKey":"",
            "nAmount": quantity, 
          };
          this.cartService.deleteOrderLine(cartProductRest, index).subscribe(res => {
            this.spinner.hide();
            // console.log(res)
          });
          // console.log('change web basket')
  
        }else{

            this.cartService.postOrderLine(cartProductRest).subscribe(res => {
              // console.log(res)
              this.spinner.hide();
              if(res.status === 401){
                // this.presentAlert();
                this.postOrder();
              }
            });
        
        }
      }

    // this.presentToast(cartProduct.sDescription1);
  }else{
    let cartProduct = {
      product_id: item.sArticleCode,
      sDescription1: item.sDescription1,
      sArticleBrand: item.sArticleBrand,
      sPromoImage: item.sPromoImage,
      sArticleCode: item.sArticleCode,
      sBlArtKey: item.sBlArtKey,
      nQuantityStep: item.nQuantityStep,
      nPriceFactor: item.nPriceFactor,
      curNetPrice: item.stPrice.curNetPrice,
      curNetPriceInc: item.stPrice.curNetPriceInc,
      count: item.nQuantity,
      iNbPoints: item.iNbPoints,
      totalPrice: productPrice
    };
    let cartProductRest =       {
      "sArticleCode":item.sArticleCode,
      "sBlArtKey":this.blartkey,
      "nAmount": item.nQuantity, 
      };

      if(!this.orderlines){

        // this.cartService.addToCart(cartProduct);
          this.cartService.postOrderLine(cartProductRest).subscribe(res => {
            this.spinner.hide();
            if(res.status === 401){
              this.postOrder();
            }
          });
      }else{
        if(this.orderlines.find(items => items.sArticleCode === item.sArticleCode)){
          console.log('item already in order')
          let sale = this.orderlines.find(items => items.sArticleCode === item.sArticleCode)
          this.orderline = sale

          let quantity = this.orderline.nAmount + item.nQuantity
          let index = this.orderline.iLineID;
          let cartProductRest =       {
            "sArticleCode":item.sArticleCode,
            "sBlArtKey":"",
            "nAmount": quantity, 
          };
            this.cartService.deleteOrderLine(cartProductRest, index).subscribe(res => {
              this.spinner.hide();
            });


  
        }else{
          console.log('New item in order')
          this.cartService.addToCart(cartProduct);
          console.log(cartProductRest)
            this.cartService.postOrderLine(cartProductRest).subscribe(res => {
              this.spinner.hide();
              console.log(res.status)
              if(res.status === 401){
                // this.presentAlert();
       
                this.postOrder();
              }
            });
            // console.log(this.cartService.postOrderLineNative(cartProductRest))
            // console.log('add native basket')
        
        }
      }

    // this.presentToast(cartProduct.sDescription1);
  }
}, 2000);

}else if(item.nQuantity === 0 || item.nQuantity === "" || item.nQuantity === NaN){
  // this.presentnorderfactor(item)
}else{
  // this.presentnorderfactor(item)
}
}


postOrder() {
    this.cartService.postOrder()
    .subscribe(data => {
      // console.log('responseorder', data);
      // console.log('native post order')
      this.cartService.postOrderNativeNumber(data)
    }, err => {
      // console.log(err);
    });
}

async getOrderLines() {
    this.cartService.getOrderLine()
    .subscribe(data => {
      // console.log('orderlines data', data)
      if(data.status === 204 || data.status === 401){
        this.orderlines = '';
      }else{
        
        this.orderlines = data['stOrderLines']['arrOrderLine'];
      }
      this.orderlines = data['stOrderLines']['arrOrderLine'];
      // loading.dismiss();
    }, err => {
      // console.log(err);
      // loading.dismiss();
    });
}


 public onMouseMove(e){
  if(window.innerWidth >= 1280){
    var image, offsetX, offsetY, x, y, zoomer;
    image = e.currentTarget;
    offsetX = e.offsetX;
    offsetY = e.offsetY;
    x = offsetX/image.offsetWidth*100;
    y = offsetY/image.offsetHeight*100;
    zoomer = this.zoomViewer.nativeElement.children[0];
    if(zoomer){
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
      zoomer.style.display = "block";
      zoomer.style.height = image.height + 'px';
      zoomer.style.width = image.width + 'px';
    }
  }
}

public onMouseLeave(event){
  this.zoomViewer.nativeElement.children[0].style.display = "none";
}

public openZoomViewer(){
  this.dialog.open(ProductZoomComponent, {
    data: this.zoomImage,
    panelClass: 'zoom-dialog'
  });
}

focus(event: any = {}, item:any) {
  // console.log('event focus', event)
  // this.focusquantity = parseInt(event.srcElement.value);
  // item.nQuantity =  item.nQuantityStep 
  item.amount = 1 * (item.stPrice.curNetPriceInc);
  console.log(event)
  if(item.nQuantity === NaN){
    console.log('is nan')
    item.nQuantity = item.nQuantityMin
    event.target.value = item.nQuantityMin
    event.target.value.replace(",", "");
    event.target.value.replace(".", "");
    item.amount = 1 * (item.stPrice.curNetPriceInc);
  }
  const searchTerm = event.target.value;
  // console.log(parseFloat(searchTerm));
  // item.nQuantity = parseFloat(searchTerm);
  // item.amount = item.nQuantity * (item.stPrice.curNetPriceInc);
  if(item.nOrderFactor>0) {
    var newvalue	= Math.floor(searchTerm/parseFloat(item.nOrderFactor)) * parseFloat(item.nOrderFactor);

    if(newvalue!=searchTerm) {newvalue += parseFloat(item.nOrderFactor);}
  } else {
    item.nQuantity = newvalue;
  }

  if(parseFloat(searchTerm)>0) { if(newvalue > parseFloat(item.nQuantityMax)) { newvalue = parseFloat(item.nQuantityMax); } }
  if(newvalue < parseFloat(item.nQuantityMin)) { newvalue = parseFloat(item.nQuantityMin); }

  item.nQuantity	= newvalue;
  
}

losesFocus(event: any = {}, item:any) {
  console.log(event)
  if(item.nQuantity === NaN){
    // console.log('is nan')
    item.nQuantity = item.nQuantityMin
    item.amount = 1 * (item.stPrice.curNetPriceInc);
  }
  if(event.type === "ionChange"){
    if(event.target.value === ""){
      // console.log('is nan input')
      item.nQuantity = 1
      item.amount = 1 * (item.stPrice.curNetPriceInc);
    }else{
    var text = event.target.value
    var howManyCommasDoIHave = text.replace(",", "");
    var oke = howManyCommasDoIHave.replace(".", "");
    item.nQuantity = parseInt(oke)
    // console.log('wihoutcomma', howManyCommasDoIHave)
    }
  }else if(event.type === "click"){
    // const oke = item.nQuantity
    // console.log('grealet', oke)
    // const y = this.focusquantity
    // const yeah = oke + item.nQuantityStep;
    // console.log(yeah)
    // item.nQuantity = yeah
    item.nQuantity= isNaN(item.nQuantity) ? 0 : item.nQuantity;
    item.nQuantity = item.nQuantity + item.nQuantityStep;
    var newvalue = Math.floor(item.nQuantity/item.nQuantityStep) * item.nQuantityStep;
    if(newvalue < item.nQuantityMin) { newvalue = item.nQuantityMin;}
    if(item.nQuantityMax>0) { if(newvalue > item.nQuantityMax) { newvalue = item.nQuantityMax; } }
    item.nQuantity = newvalue
  }else{
    item.nQuantity = item.nQuantityStep
  }
}

losesKGFocus(event: any = {}, item:any) {
// console.log(event)
if(item.nQuantity === NaN){
  // console.log('is nan')
  item.nQuantity = item.nQuantityMin
  item.amount = 1 * (item.stPrice.curNetPriceInc);
}
if(event.type === "ionChange"){
  if(event.detail.value === ""){
    // console.log('is nan input')
    item.nQuantity = parseFloat(item.nQuantityMin)
    item.amount = 1 * (item.stPrice.curNetPriceInc);
  }else{
  var text = event.detail.value
  // var howManyCommasDoIHave = text.replace(",", "");
  // var oke = howManyCommasDoIHave.replace(".", "");
    if(item.nOrderFactor === 1){
    item.nQuantity = parseFloat(event.detail.value)
    }else if(item.nOrderFactor > 1){
      var oke = parseFloat(event.detail.value)
      var yes = item.nQuantityStep + oke
      item.nQuantity = oke
    }
  }
}else if(event.type === "click"){
  // const oke = item.nQuantity
  // console.log('grealet', oke)
  // const y = this.focusquantity
  // const yeah = oke + item.nQuantityStep;
  // console.log(yeah)
  // item.nQuantity = yeah
  item.nQuantity= isNaN(item.nQuantity) ? 0 : item.nQuantity;
  item.nQuantity = item.nQuantity + item.nQuantityStep;
  var newvalue = Math.floor(item.nQuantity/item.nQuantityStep) * item.nQuantityStep;
  if(newvalue < item.nQuantityMin) { newvalue = item.nQuantityMin;}
  if(item.nQuantityMax>0) { if(newvalue > item.nQuantityMax) { newvalue = item.nQuantityMax; } }
  item.nQuantity = newvalue
}else{
  if(item.nOrderFactor === item.nQuantityStep){
  item.nQuantity = item.nQuantityStep
  }
}
}

losesMinFocus(event: any = {}, item:any) {
console.log(event)
if(item.nQuantity === NaN){
  // console.log('is nan')
  item.nQuantity = item.nQuantityMin
  item.amount = 1 * (item.stPrice.curNetPriceInc);
}
if(event.type === "ionChange"){
  if(event.detail.value === ""){
    // console.log('is nan input')
    item.nQuantity = item.nQuantityMin
    item.amount = 1 * (item.stPrice.curNetPriceInc);
  }else{
  item.nQuantity = parseFloat(event.detail.value)
  }
}else if(event.type === "click"){
  item.nQuantity= isNaN(item.nQuantity) ? 0 : item.nQuantity;
  item.nQuantity = item.nQuantity - item.nQuantityStep;
  var newvalue = Math.floor(item.nQuantity/item.nQuantityStep) * item.nQuantityStep;
  if(newvalue < item.nQuantityMin) { newvalue = item.nQuantityMin;}
  if(item.nQuantityMax>0) { if(newvalue > item.nQuantityMax) { newvalue = item.nQuantityMax; } }
  item.nQuantity = newvalue
}else{
  item.nQuantity = item.nQuantityStep
}
}

losesMinKGFocus(event: any = {}, item:any) {
// console.log(event)
if(item.nQuantity === NaN){
// console.log('is nan')
item.nQuantity = item.nQuantityMin
item.amount = 1 * (item.stPrice.curNetPriceInc);
}
if(event.type === "ionChange"){
if(event.detail.value === ""){
  // console.log('is nan input')
  item.nQuantity = item.nQuantityMin
  item.amount = 1 * (item.stPrice.curNetPriceInc);
}else{
  var text = event.detail.value
  var howManyCommasDoIHave = text.replace(",", "");
  var oke = howManyCommasDoIHave.replace(".", "");
  item.nQuantity = parseFloat(event.detail.value)
  // console.log('wihoutcomma', howManyCommasDoIHave)
}
}else if(event.type === "click"){
item.nQuantity= isNaN(item.nQuantity) ? 0 : item.nQuantity;
item.nQuantity = item.nQuantity - item.nQuantityStep;
var newvalue = Math.floor(item.nQuantity/item.nQuantityStep) * item.nQuantityStep;
if(newvalue < item.nQuantityMin) { newvalue = item.nQuantityMin;}
if(item.nQuantityMax>0) { if(newvalue > item.nQuantityMax) { newvalue = item.nQuantityMax; } }
item.nQuantity = newvalue
}else{
item.nQuantity = item.nQuantityStep
}
}



}


